import { createContext, useEffect, useState } from "react";
import axios from "axios"
import { loginUri, logoutUri } from "../uri";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("SCARGO")) || null);

    const login = async(user) => {
       try{
            const res = await axios.post(loginUri, user)
            if(res.status === 204){
                return toast({title: 'Уучлаарай',description: "Ийм дугаартай харилцагч байхгүй байна !",status: 'error',duration: 8000,isClosable: true,}) 
            }
            if(res.status === 205){
                return toast({title: 'Уучлаарай',description: "Хэрэглэгчийн утасны дугаар эсвэл нууц үг таарахгүй байна !",status: 'error',duration: 8000,isClosable: true,}) 
            }
            if(res.status === 200){
                setCurrentUser(res.data);
                navigate('/profile');
                return toast({title: 'Амжилттай',description: "Дархан бүсийн карго -д тавтай морилно уу",status: 'success',duration: 8000,isClosable: true,}) 
            }
       }catch(err){
        console.log(err)
       }
    }

    const logout = async(user) => {
        // await axios.post(logoutUri, user)
        setCurrentUser(null)
        navigate('/login');
    };

    useEffect(()=>{
        localStorage.setItem("SCARGO", JSON.stringify(currentUser));
    }, [currentUser]);

    return(
        <AuthContext.Provider value={{currentUser, login, logout}}>
            {children}
        </AuthContext.Provider>
    )

}