import React from 'react';
import { RiDeleteBin5Line } from "react-icons/ri";
import AddressDeleteModal from './addressDelete';


const AddressCard = ({data, callback}) => {
  return (
    <div className='bg-gray-300 font-Roboto p-4 rounded-xl mt-2 text-gray-700'>
        <div className='flex justify-end'>
            <AddressDeleteModal callback={callback} data={data}/>
        </div>
        <div className='flex gap-6'>
            <div>
                <h1 className='text-gray-600 text-sm'>Аймаг / Хот</h1>
                <p className='uppercase text-sm font-bold'>{data.city}</p>
            </div>
            <div>
                <h1 className='text-gray-600 text-sm'>Сум / Дүүрэг</h1>
                <p className='uppercase text-sm font-bold'>{data.district}</p>
            </div>
        </div>
        <div className='mt-2'>
            <h1 className='text-gray-600 text-sm'>Баг / Хороо</h1>
            <p className='uppercase text-sm font-bold'>{data.khoroo}</p>
        </div>
        {/* <div className='mt-2'>
            <h1 className='text-gray-300 text-sm'>Байр, хороолол, гудамж</h1>
            <p className='uppercase text-sm font-bold'>{data.city}</p>
        </div> */}
        <div className='mt-2'>
            <h1 className='text-gray-600 text-sm'>Хаяг</h1>
            <p className='uppercase text-sm font-bold'>{data.address}</p>
        </div>
    </div>
  )
}

export default AddressCard