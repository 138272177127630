import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { POST } from '../../../utils/requests';
import { registerUri } from '../../../uri';
import { useToast } from '@chakra-ui/react';
import Loading from '../../../components/Loading';

const Register = () => {
  const [data, setData] = useState({username:"", phone_no:"", password:"", cpassword:""});
  const [error, setError] = useState({usernameE:"", phone_noE:"", passwordE:"", cpasswordE:""});
  const nav = useNavigate();
  const toast = useToast();
  const [load, setLoad] = useState(false);

  const submit = async () => {
   
    if(error.usernameE === "" && error.phone_noE === "" && error.passwordE === "" && error.cpasswordE === ""){
    }else{
      return
    }
    
    if(data.username === "" || data.phone_no==="" || data.password==="" || data.cpassword===""){
      return toast({title: 'Алдаа',description: "Та мэдээлэлээ оруулна уу!",status: 'warning',duration: 5000,isClosable: true,});
    }
    
    setLoad(true);

    try{
      const res = await POST({uri:registerUri, data: data});

      if(res.status === 209){
        toast({title: 'Уучлаарай',description: "Таны утасны дугаар бүртгэлтэй байна !",status: 'error',duration: 5000,isClosable: true,});
      }

      if(res.status === 201){
        toast({title: 'Амжилттай',description: "Таны бүртгэл амжилттай боллоо",status: 'success',duration: 5000,isClosable: true,});
        nav("/login");
      }
      
      setLoad(false);
    }catch(err){
      setLoad(false);
      console.log(err);
    }
  }

  const loginnav = async () => {
    nav('/login');
  }

  const handleCheck = (e) => {
    const { name, value } = e;
    let errorMessage = "";
  
    switch (name) {
      case "username":
        if (value.length === 0) {
          errorMessage = "Нэрээ оруулна уу!";
        } else if (value.length <= 2) {
          errorMessage = "Хэт богино нэр байна!";
        }
        break;
      case "phone_no":
        if (value.length === 0) {
          errorMessage = "Утасны дугаараа оруулна уу!";
        } else if (value.length <= 7) {
          errorMessage = "Буруу дугаар байна!";
        }else if (value.length > 8) {
          errorMessage = "8 оронтой дугаар оруулна уу!";
        }
        break;
      case "password":
        if (value.length === 0) {
          errorMessage = "Нууц үгээ оруулна уу!";
        } else if (value.length <= 3) {
          errorMessage = "Хэт богино нууц үг байна!";
        }
        break;
      case "cpassword":
        if(data.password === ""){
          errorMessage = "Буруу байна!"
        }
        if (data.password !== value) {
          errorMessage = "Нууц үг таарахгүй байна!";
        }
        break;
      default:
        break;
    }
  
    setError({ ...error, [`${name}E`]: errorMessage });
    setData({ ...data, [name]: value });
  };
  

  return (
    <div className='font-Roboto text-white'>
      {
        load && <Loading/>
      }
      <div>
        <h1 className='text-white text-center mt-10 uppercase text-xl'>Дархан бүсийн каргонд тавтай морилно уу</h1>
        <div className='mt-8'>
          <h1 className='mb-2 text-gray-300 text-sm'>Таны нэр</h1>
          <input onChange={(e)=> handleCheck({name: "username", value: e.target.value})} placeholder='Таны нэр' className='w-full
          p-3 rounded-xl outline-none bg-white text-black focus:ring-2' type=''/>
          <p className='mt-2 text-red-300 text-sm font-bold text-end'>{error.usernameE}</p>

          <h1 className='mb-2 mt-3 text-sm text-gray-300'>Утасны дугаар</h1>
          <input onChange={(e)=> handleCheck({name: "phone_no", value: e.target.value})} placeholder='Утасны дугаар' className='w-full
          p-3 rounded-xl outline-none bg-white text-black focus:ring-2' type='number'/>
          <p className='mt-2 text-red-300 text-sm font-bold text-end'>{error.phone_noE}</p>

          <h1 className='mb-2 mt-3 text-sm text-gray-300'>Нууц үг</h1>
          <input onChange={(e)=> handleCheck({name: "password", value: e.target.value})} placeholder='Нууц үг' className='w-full
          p-3 rounded-xl outline-none bg-white text-black focus:ring-2' type='password'/>
          <p className='mt-2 text-red-300 text-sm font-bold text-end'>{error.passwordE}</p>

        <h1 className='mb-2 mt-3 text-sm text-gray-300'>Нууц үг давтах</h1>
          <input onChange={(e)=> handleCheck({name: "cpassword", value: e.target.value})} placeholder='Нууц үг давтах' className='w-full
          p-3 rounded-xl outline-none bg-white text-black focus:ring-2' type='password'/>
          <p className='mt-2 text-red-300 text-sm font-bold text-end'>{error.cpasswordE}</p>

          <div className='flex justify-center mt-4'>
            <button onClick={submit} className='w-2/3 border-2 p-3 rounded-full shadow-md shadow-[#7f7fd1] text-black font-bold
             hover:shadow-sm bg-gray-200'>Бүртгүүлэх</button>
          </div>

          <div className='mt-16 flex items-center w-full'>
            <div className='border border-gray-300 w-full rounded-full'></div>
            <h1 className='text-center text-gray-300 text-md w-[880px]'>Нэвтрэх хуудасруу буцах</h1>
            <div className='border border-gray-300 rounded-full w-full'></div>
          </div>
          <button onClick={loginnav} className='w-full border-2 p-4 rounded-full shadow-md shadow-white hover:shadow-sm mt-4'>Нэвтрэх</button>
        </div>
      </div>
    </div>
  )
}

export default Register