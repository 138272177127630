import React, { useContext, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { AuthContext } from '../../../context/auth';
import Loading from '../../../components/Loading';
import { useToast } from '@chakra-ui/react';

const Login = () => {
  const nav = useNavigate();
  const [data, setData] = useState({phone_no:"", password:""});
  const {login} = useContext(AuthContext);
  const toast = useToast();
  const [load, setLoad] = useState(false);

  const register = () => {
    nav('/register');
  }
  const logins = async() => {
    if(data.phone_no === "" || data.password === ""){
      return toast({title: 'Алдаа',description: "Мэдээллээ бүрэн оруулна уу!",status: 'warning',duration: 5000,isClosable: true,});
    }
    setLoad(true);
    await login(data);
    setLoad(false);
  }

  return (
    <div className='font-Roboto text-white'>
      {
        load && <Loading/>
      }
      <div>
        <h1 className='text-white text-center mt-10 uppercase text-xl'>Дархан бүсийн каргонд тавтай морилно уу</h1>
        <div className='mt-8'>
          <h1 className='mb-2 text-gray-300'>Утасны дугаар</h1>
          <input onChange={(e) => setData({...data, phone_no: e.target.value})} placeholder='Утасны дугаар' className='w-full
          p-4 rounded-xl outline-none bg-white text-black focus:ring-2' type='number'/>

          <h1 className='mb-2 mt-4 text-gray-300'>Нууц үг</h1>
          <input onChange={(e) => setData({...data, password: e.target.value})} placeholder='Нууц үг' className='w-full
          p-4 rounded-xl outline-none bg-white text-black focus:ring-2' type='password'/>

          <div className='flex items-center mt-6'>
            <Link to="/resetpass" className='uppercase text-md text-gray-300 w-80 xs:text-xs md:text-md'>Нууц үг мартсан ?</Link>
            <button onClick={logins} className='w-full border-2 p-4 rounded-full shadow-md shadow-[#7f7fd1] text-black font-bold
             hover:shadow-sm bg-gray-200'>Нэвтрэх</button>
          </div>

          <div className='mt-16 flex items-center w-full'>
            <div className='border border-gray-300 w-full rounded-full'></div>
            <h1 className='text-center text-gray-300 text-md w-[800px]'>Шинээр бүртгэл үүсгэх</h1>
            <div className='border border-gray-300 rounded-full w-full'></div>
          </div>
          <button onClick={register} className='w-full border-2 p-4 rounded-full shadow-md shadow-white hover:shadow-sm mt-4'>Бүртгүүлэх</button>
        </div>
      </div>
    </div>
  )
}

export default Login