import React from 'react';
import { PiNotebookFill } from "react-icons/pi";
import { FiPhoneCall } from "react-icons/fi";
import {AspectRatio} from '@chakra-ui/react'


const Contact = () => {
  return (
    <div className='text-white mt-8'>
         <div className='flex items-center gap-4'>
            {/* <TfiWrite /> */}
            <div>
                <h1 className='font-bold text-md uppercase text-xl'>Холбоо барих</h1>
                <p>Contact</p>
            </div>
        </div>

        <div className='mt-14'>
            <div className='flex justify-center'>
                <FiPhoneCall size={40}/>
            </div>
            <h1 className='text-center uppercase text-sm mt-4 font-bold'>Хаяг байршил :</h1>
            <p className='text-sm text-gray-300 text-center mt-4'>Дархан хот Шинэ хороолол Магнолиа хотхон 335-р байр</p>
            <p className='text-center uppercase font-bold text-sm mt-4'>Оффисс утас: 99739959</p>

            <div className='mt-4'>
            <AspectRatio ratio={16 / 18}>
              <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2592.845440785864!2d105.969214!3d49.46853499999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDnCsDI4JzA2LjciTiAxMDXCsDU4JzA5LjIiRQ!5e0!3m2!1sen!2smn!4v1724123300788!5m2!1sen!2smn" />
            </AspectRatio>
            </div>
        </div>
    </div>
  )
}

export default Contact