import { Spinner, useToast } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FaRegCopy } from "react-icons/fa6";
import { RiDeleteBin5Line } from 'react-icons/ri';
import moment from "moment";
import { TYPES } from '../../../utils/type';
import { GET } from '../../../utils/requests';
import { getuseruri } from '../../../uri';
import { AuthContext } from '../../../context/auth';


const ProductCheckCard = ({data, callback}) => {
    const toast = useToast();
    const [load, setLoad] = useState(false);
    const {currentUser} = useContext(AuthContext);

  function CopyAlert(){
    toast({title: 'Хууллаа',status: 'success',duration: 3000,isClosable: true,})
  }

  const call = () => {
    callback();
  }

  const deleteSub = async() => {
    setLoad(true);
    const res = await GET(getuseruri+`/track/delete?token=${currentUser}&id=${data.id}`);
    if(res.status === 200){
        call();
        setLoad(false);
        toast({title: "Трак код устгалаа", description: "Дархан бүсийн каргог сонгодог таньд баярлалаа", status: "success", duration: 5000,isClosable: true,});
    }
    setLoad(false);
  }

  return (
    <div className='w-full'>
        <div className='text-gray-700 mt-2'>
            <div className='p-2 bg-gray-300 rounded-md'>
                <div className='flex justify-end'>
                    {
                        data.status === TYPES.REQUESTED &&
                        <>
                            {
                                load?
                                <Spinner/>
                                :
                                <RiDeleteBin5Line onClick={deleteSub} className='absolute bg-yellow-500 rounded-md shadow-sm shadow-[#7878c2] p-1' size={24}/>
                            }
                        </>
                    }
                </div>
                <h1 className='text-xs font-bold uppercase text-gray-600'>{data.note}</h1>
                <CopyToClipboard text={data.trackcode}>
                    <div className='flex gap-2 items-center cursor-pointer'>
                        <h1 className='text-md font-bold uppercase mt-1'>{data.trackcode}</h1>
                        <FaRegCopy onClick={CopyAlert}/>
                    </div>
                </CopyToClipboard>
                <div className='mt-2 text-gray-600'>
                    <h1 className='text-sm'>Огноо</h1>
                    {
                        data.status === TYPES.REQUESTED ?
                        <h1 className='text-md'>{moment(data.created_date).format('YYYY/MM/DD')}</h1>
                        :
                        <h1 className='text-md'>{moment(data.date).format('YYYY/MM/DD HH:mm')}</h1>
                    }
                    <h1 className='text-sm mt-2'>Карго төлбөр: <span className='font-bold'>{data.payment}</span> ₮</h1>
                </div>
                <div className='flex justify-end'>
                    <div className='absolute bg-yellow-500 text-gray-100 p-1 xs:text-xs md:text-sm rounded-md mt-[-30px]'>
                        {data.status === TYPES.REQUESTED && "Бүртгэсэн"}
                        {data.status === TYPES.IRSEN && (!data.delivery || data.delivery === "") && "Ирсэн"}
                        {data.delivery === TYPES.APPROVED && "Хүргэлт баталгаажсан"}
                        {data.status === TYPES.EREEN && "Эрээнд"}
                        {data.status === TYPES.CONFIRM && (!data.delivery || data.delivery === "") && "Авсан"}
                        {data.delivery === TYPES.CONFIRM && "Хүргэлтээр авсан"}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ProductCheckCard